.password-control {
  input.form-control {
    padding-right: 2.25rem;

    .was-validated &:valid,
    .was-validated &:invalid {
      padding-right: 3.8rem;
      background-position-x: right 2.2em;
    }
  }

  .visibility-icon {
    top: 0;
    right: 0.5rem;
  }
}
