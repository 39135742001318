.topbar {
  background: var(--topbar-background, #e8e5e5) !important;

  &-brand {
    max-width: 100%;
    max-height: 3em;
    padding: 0 3.5em;
    object-fit: contain;
  }

  &-group {
    &.active {
      position: relative;
      transform: translateY(-0.5em);
      margin-bottom: -1em;
      z-index: 1;
      clip-path: inset(-100% -100% 0.5em -100%);
    }
  }
}

#offcanvas-navigation-menu {
  width: 20em;
}

.profile-link {
  grid-template-columns: 3.2em auto;
  grid-template-rows: 2.2em 1em;
  grid-column-gap: 0.8em;
  grid-template-areas:
    "icon name"
    "icon cta";

  .viewer {
    &-icon {
      border-radius: 1.5em;
      line-height: 3.2em;
      grid-area: icon;
    }
    &-name {
      grid-area: name;
      text-overflow: ellipsis;
    }
    &-cta {
      grid-area: cta;
      font-size: 0.9em;
    }
  }
}
