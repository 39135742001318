.locale-selector {
  max-width: 100%;

  &.valid {
    .locale-selector__control {
      border-color: var(--bs-success);
      box-shadow: inset 0 1px 1px rgba(var(--bs-success), 0.075);
    }

    .locale-selector__control:hover {
      border-color: var(--bs-success);
      box-shadow: 0 0 0 0.2rem rgba(var(--bs-success), 0.25);
    }
  }

  &.invalid {
    .locale-selector__control {
      border-color: var(--bs-danger);
      box-shadow: inset 0 1px 1px rgba(var(--bs-danger), 0.075);
    }

    .locale-selector__control:hover {
      border-color: var(--bs-danger);
      box-shadow: 0 0 0 0.2rem rgba(var(--bs-danger), 0.25);
    }
  }
}
