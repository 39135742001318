.app-background-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 15em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.form-label {
  font-weight: bold;
  color: var(--bs-gray-700);
}
